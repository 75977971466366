import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import {
  CustomGrid,
  CustomGridBG,
  SectionHeading,
} from "../../../Styles/style";
import aboutBG from "../../../../assets/images/aboutBG.png";
import ElonAIvideo from "../../../../assets/images/ElonAIvideo.mp4";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  min-height: 130vh;
  width: 100%;
  background-color: #000;
  background-image: url(${aboutBG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0px !important;
  position: relative;
  margin-bottom: 100px;
  @media (min-width: 1399px) {
    margin-bottom: 300px;
  }
  @media (max-width: 599px) {
    min-height: 100vh;
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  min-height: 100vh;
  padding: 0px !important;
  position: relative;
  z-index: 1;
  @media (min-width: 1400px) {
    min-height: auto;
  }
  @media (max-width: 999px) {
    min-height: auto;
  }
  @media (max-width: 599px) {
    background-image: none;
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 50px 0px;
  display: flex;
  align-items: start;
  justify-content: space-around;
  @media (max-width: 599px) {
    padding: 0;
  }
`;

const VideoBk = styled.video`
  width: 100%;
  object-fit: contain;
  background-color: #000;
  border: 1px solid #6cff77;
`;
const BackgroundShadowDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  box-shadow: 0px -35px 28px 0px rgba(0, 0, 0, 1) inset,
    0px 35px 28px 13px rgba(0, 0, 0, 1),
    0px 35px 28px 0px rgba(0, 0, 0, 1) inset,
    0px -35px 28px 9px rgba(0, 0, 0, 1);
  z-index: 0;
`;
const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about">
        <InnerContinerWraper>
          <GridWraper container>
            <CustomGrid container item xs={12} data-aos="fade-up" g="30px">
              <CustomGridBG
                item
                xs={8}
                sm={5}
                md={4}
                p="20px"
                bp="center"
                sx={{}}
              >
                <SectionHeading>About</SectionHeading>
              </CustomGridBG>
              <Grid item xs={12} sm={10} md={9}>
                <Paragraph ta="center" ta599="center" p="10px 0">
                  At the forefront of the decentralized uprising stands ELON AI,
                  a revolutionary project designed to safeguard the digital
                  frontier. Born from the collective wisdom of a community that
                  values balance and ethical advancement, ELON AI is not just a
                  token; it's a sentinel against unchecked technological
                  dominance.
                </Paragraph>
              </Grid>
              <CustomGrid
                item
                xs={12}
                sm={10}
                m="50px 0"
                sx={{
                  position: "absolute",
                  top: { xs: "52%", sm: "40%" },
                  left: "50%",
                  transform: {
                    xs: "translate(-50%,52%)",
                    sm: "translate(-50%,40%)",
                  },
                }}
              >
                <VideoBk controls>
                  <source src={ElonAIvideo} type="video/mp4" />
                </VideoBk>
              </CustomGrid>
            </CustomGrid>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;
