import React from "react";
import { Container, Grid, Link } from "@mui/material";
import styled from "@emotion/styled";
import {
  CustomGrid,
  CustomGridBG,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import x from "../../../../assets/images/x.png";
import footerBG from "../../../../assets/images/footerBG.png";
import foterImage from "../../../../assets/images/foterImage.png";
import tg from "../../../../assets/images/tg.png";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  width: 100%;
  min-height: 100vh;
  background-image: url(${footerBG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px !important;
  position: relative;
  display: flex;
  align-items: end;
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0px !important;
  border-radius: 20px;
  min-height: 100vh;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: start;
  @media (max-width: 599px) {
    /* padding: 0 20px !important; */
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  row-gap: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
  @media (max-width: 599px) {
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 30px;
  @media (max-width: 599px) {
    align-items: center;
  }
`;

const Footer = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="fetures">
            <DataImage mw="30%" mw899="50%" src={foterImage} style={{position:"absolute",bottom:"0",left:"0"}}/>
        <InnerContinerWraper data-aos="fade-up">
          <GridWraper container>
            <GridItem container item xs={12} sm={8} xl={12}>
              <CustomGridBG
                item
                xs={12}
                xl={8}
                p="20px"
                bp="center"
                m="20px 0"
                sx={{}}
              >
                <SectionHeading> Disclamer</SectionHeading>
              </CustomGridBG>
              <GridItem item xs={12}>
                <Paragraph ta599="left">
                  ELON AI is not affiliated with Elon Musk; the use of the name
                  is for the sake of parody and falls under fair use policy.
                  Cryptocurrency investments carry risks, and ELON AI tokens may
                  fluctuate in value. Conduct independent research and assess
                  your risk tolerance before participating. ELON AI makes no
                  guarantees, and the project is subject to community consensus
                  and market dynamics. Compliance with legal regulations is a
                  priority, and participants are urged to stay informed about
                  any disclaimer updates.
                </Paragraph>
              </GridItem>
              <CustomGrid itam xs={12} g="20px" jc="start">
                <Link href="https://twitter.com/elonai_eth" target="blank">
                  <DataImage mw="40px" src={x} alt="" />
                </Link>
                <Link href="https://t.me/elonai_eth" target="blank">
                  <DataImage mw="40px" src={tg} alt="" />
                </Link>
              </CustomGrid>
            </GridItem>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
      <div
        style={{
          display: "grid",
          placeItems: "center",
          padding: "5px 0",
        }}
      >
        <Paragraph fs="14px" ta="center">
          Elon AI © 2023, All rights reserved
        </Paragraph>
      </div>
    </>
  );
};

export default Footer;
