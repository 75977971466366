import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import {
  CustomGrid,
  CustomGridBG,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import heroBG from "../../../../assets/images/heroBG.jpg";
import enemy from "../../../../assets/images/enemy.png";
import LockerMenu from "../../LockerMenu";
import Paragraph from "../../Paragraph";
import AboutSection from "../S2-About";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-image: url(${heroBG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0 !important;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: 50% 70%;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  padding: 0 50px !important;
  /* border-radius: 20px; */
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;
const BackgroundShadowDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px -35px 28px 0px rgba(0, 0, 0, 1) inset;
  z-index: 0;
`;
const HeroSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="home">
      <BackgroundShadowDiv/>
        <InnerContinerWraper>
          <LockerMenu />
          <CustomGrid
            container
            jc="start"
            ai="center"
            p="50px 0"
            fd599="column-reverse !important"
            data-aos="fade-up"
            sx={{ minHeight: "100vh" }}
          >
            <CustomGrid container item xs={12} sm={7} fd="column" ai="start">
              <CustomGridBG
                item
                xs={10}
                sm={11}
                md={9}
                p="20px"
                jc="start"
                sx={{}}
              >
                <SectionHeading>Elon AI</SectionHeading>
              </CustomGridBG>
              <CustomGrid
                container
                item
                xs={12}
                jc="start"
                ai="start"
                m="50px 0"
              >
                <CustomGrid item xs={3} sm={2} jc="start" p="10px" ai="start">
                  <DataImage src={enemy} />
                </CustomGrid>
                <CustomGrid item xs={12} sm={10} jc="start">
                  <Paragraph ta599="left">
                    In the sprawling landscape of technological innovation, a
                    new force emerges to counterbalance the ambitious endeavors
                    of a visionary mind. Welcome to the world of "ELON AI,"
                    where the relentless pursuit of progress meets its match. As
                    the realms of artificial intelligence and cryptocurrency
                    converge, a decentralized resistance takes shape, ready to
                    curb the boundless ambitions of a singular force.
                  </Paragraph>
                </CustomGrid>
              </CustomGrid>
              <CustomGrid item xs={12} jc="start">
                <div
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #6CFF77",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Paragraph fm="Glitch" sx={{ wordBreak: "break-all" }}>
                    Contract Address:
                  </Paragraph>
                  <Paragraph sx={{ wordBreak: "break-all" }}>
                  0xD5D1FB5835651C80Ead387A75bD04611e8B3aD52
                  </Paragraph>
                </div>
              </CustomGrid>
            </CustomGrid>
            {/* <CustomGrid item xs={12} sm={5} data-aos="zoom-in">
            <DataImage mw="70%" src={heroImage} alt="" />
          </CustomGrid> */}
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
      <AboutSection />
    </>
  );
};

export default HeroSection;
