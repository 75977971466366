import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import {
  CustomGrid,
  CustomGridBG,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import howBG from "../../../../assets/images/howBG.png";
import wallet from "../../../../assets/images/wallet.png";
import ethereum from "../../../../assets/images/ethereum.png";
import uniswap from "../../../../assets/images/uniswap.png";
import coins from "../../../../assets/images/coins.png";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${howBG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 0px !important;
  position: relative;
  @media (max-width: 899px) {
    padding: 100px 0 100px !important;
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 20px 0px 0;
  row-gap: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 599px) {
    padding: 0;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 599px) {
    align-items: center;
  }
`;
const ColorGrid = styled(Grid)`
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  background: ${(props) => (props.back ? props.back : "rgba(32,47,37,.7)")};
  border: ${(props) => (props.b ? props.b : "1px solid #6CFF77")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  @media (max-width: 599px) {
    align-items: center;
  }
`;

const Humour = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl">
        <InnerContinerWraper>
          <GridWraper container data-aos="fade-up">
            <GridItem container item xs={12}>
              <CustomGridBG
                item
                xs={12}
                sm={8}
                p="20px"
                bp="center"
                m="20px 0"
                sx={{}}
              >
                <SectionHeading>How To Buy</SectionHeading>
              </CustomGridBG>
            </GridItem>
            <CustomGrid container item xs={12} sm={11} md={12} g="20px">
              <CustomGrid
                container
                item
                xs={12}
                sm={8}
                md={5.7}
                jc="start"
                ai="start"
              >
                <ColorGrid item xs={3} p="2%" back="#0A100E">
                  <DataImage mw="70px" src={wallet} />
                </ColorGrid>
                <ColorGrid item xs={9} fd="column" ai="start" p="10px">
                  <Paragraph fm="Glitch" fs="12px" fs599="10px">
                    Create A Metmask Wallet
                  </Paragraph>
                  <Paragraph fs="10px" fs599="8px">
                    Create a Metmask Wallet using either a desktop computer or
                    an iOS/Android mobile device. That will allow you to buy,
                    sell, send, and receive Grok Inu.
                  </Paragraph>
                </ColorGrid>
              </CustomGrid>
              <CustomGrid
                container
                item
                xs={12}
                sm={8}
                md={5.7}
                jc="start"
                ai="start"
              >
                <ColorGrid item xs={3} p="2%" back="#0A100E">
                  <DataImage mw="70px" src={ethereum} />
                </ColorGrid>
                <ColorGrid item xs={9} fd="column" ai="start" p="10px">
                  <Paragraph fm="Glitch" fs="12px" fs599="10px">
                    Send ETH To Your Wallet
                  </Paragraph>
                  <Paragraph fs="10px" fs599="8px">
                    You can buy Ethereum(ETH) directly on Metamask or transfer
                    it to your Metamask wallet from exchange like Binance,
                    Coinbase, Okx, etc.
                  </Paragraph>
                </ColorGrid>
              </CustomGrid>
              <CustomGrid
                container
                item
                xs={12}
                sm={8}
                md={5.7}
                jc="start"
                ai="start"
              >
                <ColorGrid item xs={3} p="2%" back="#0A100E">
                  <DataImage mw="70px" src={uniswap} />
                </ColorGrid>
                <ColorGrid item xs={9} fd="column" ai="start" p="10px">
                  <Paragraph fm="Glitch" fs="12px" fs599="10px">
                    Connect Your Wallet
                  </Paragraph>
                  <Paragraph fs="10px" fs599="8px">
                    Go to Uniswap and connect your wallet by clicking 'Connect
                    Wallet' and selecting Metamask.
                  </Paragraph>
                </ColorGrid>
              </CustomGrid>
              <CustomGrid
                container
                item
                xs={12}
                sm={8}
                md={5.7}
                jc="start"
                ai="start"
              >
                <ColorGrid item xs={3} p="2%" back="#0A100E">
                  <DataImage mw="70px" src={coins} />
                </ColorGrid>
                <ColorGrid item xs={9} fd="column" ai="start" p="10px">
                  <Paragraph fm="Glitch" fs="12px" fs599="10px">
                    Swap ETH For Elon AI
                  </Paragraph>
                  <Paragraph fs="10px" fs599="8px">
                    Press 'Select a token' and enter the token contract address
                    to find <span style={{fontFamily:"Glitch"}}>
                      Elon AI.
                    </span> and start your swap from ETH to{" "}
                    <span style={{fontFamily:"Glitch"}}>
                      Elon AI.
                    </span>
                  </Paragraph>
                </ColorGrid>
              </CustomGrid>
            </CustomGrid>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Humour;
